import React, { Component } from 'react';
import { Link } from 'gatsby';
import { css } from "@emotion/core";
import { Offline, Online } from 'react-detect-offline';
import { AuthUserContext } from './Session';
import { withFirebase } from './Firebase';
import SignOutButton from './SignOut';
import * as ROUTES from '../constants/routes';
import MessagesNav from '../components/notificationBell';
import MessagesNavExpand from '../components/notificationBellExpand';

function closeMenu() {
    var mainNav = document.getElementById("menu-btn");
    mainNav.checked= false;
}
function closeMenuPortfolio() {
    var mainNav = document.getElementById("menu-btn");
    mainNav.checked= false;
    var pList = document.getElementById("portfolioListContainer");
    var pHeader = document.getElementById("portfolioHeader");
    if (pList.classList.contains('archivedP')) {
        pList.classList.add('activeP');
        pList.classList.remove('archivedP');
        pHeader.classList.add('pActive');
        pHeader.classList.remove('pArchived');
    } else {}
}


const LoggedInNav = ({ authUser}) => (
  
  <React.Fragment>
    
    <input className="menu-btn" type="checkbox" id="menu-btn" />
    <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
    <Link to={ROUTES.SEARCH} title="Search SaltSmart"><span className="icons icon-search" /></Link>
    <Online><MessagesNav /></Online>
    <nav id="mainNav">
      <ul className="menu">
        <li className="linkNAV">
          <Link id="usLink" to={ROUTES.LANDING} title="Home" onClick={closeMenu}>Home</Link>
        </li>
        <li className="linkNAV">
          <Link id="usLink" to={ROUTES.US} title="U.S. Products" onClick={closeMenu}>U.S. Products</Link>
        </li>
        <li className="linkNAV disabled" >
          <a id="caLink" href="/" onClick={closeMenu}>Canada Products</a>
        </li>
        <li className="linkNAV">
          <Link id="companyInfoLink" to={ROUTES.COMPANY} title="Company Information" onClick={closeMenu}>Company Information</Link>
        </li>
        <li className="linkNAV">
          <Link id="portfolioLink" title="My Portfolios" to={ROUTES.PORTFOLIOS} onClick={closeMenuPortfolio}>My Portfolios</Link>
        </li>                                
        <li className="linkNAV">
          <Online><MessagesNavExpand /></Online>
        </li>
        <li className="linkNAV">
          <Link id="appResLink" title="App Resources" to={ROUTES.RESOURCES} onClick={closeMenu}>App Resources</Link>
        </li>    
        <li className="linkNAV"></li>
        <li className="icon-compass"></li>
        <li className="logOut">
          <SignOutButton />
        </li>
      </ul>
    </nav>
  </React.Fragment>
);

const GuestNav = () => (
  <React.Fragment>
    <input className="menu-btn" type="checkbox" id="menu-btn" />
    <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
    <Link to={ROUTES.SEARCH} title="Search SaltSmart"><span className="icons icon-search" /></Link>
    <nav id="mainNav">
      <ul className="menu">
        <li className="linkNAV">
          <Link id="usLink" to={ROUTES.GUEST} title="Home" onClick={closeMenu}>Home</Link>
        </li>
        <li className="linkNAV">
          <Link id="usLink" to={ROUTES.US} title="U.S. Products" onClick={closeMenu}>U.S. Products</Link>
        </li>
          <li className="linkNAV disabled" >
            <a id="caLink" href="/" onClick={closeMenu}>Canada Products</a>
        </li>
        <li className="linkNAV">
          <Link id="companyInfoLink" to={ROUTES.COMPANY} title="Company Information" onClick={closeMenu}>Company Information</Link>
        </li>
        <li className="linkNAV"></li>
        <li className="icon-compass"></li>
        <li className="logOut">
          <Link to={ROUTES.LANDING} onClick={closeMenu}>Sign In</Link>
        </li>    
      </ul>
    </nav>
  </React.Fragment>
);
  



class Navigation extends Component {
  render() {
    return (
      <React.Fragment>
        <AuthUserContext.Consumer>
          {authUser =>
            authUser ? (
              <LoggedInNav authUser={authUser} />
            ) : (
              <GuestNav />          
            )
          }
        </AuthUserContext.Consumer>  
      </React.Fragment>
    );
  }
}

export default withFirebase(Navigation);