//Any User Routes
export const LANDING = '/';
export const HOME = '/';
export const GUEST = '/guest';
export const SIGN_IN = '/signin';
export const SEARCH = '/search';
export const US = '/us';
export const COMPANY = '/company-information';
//Signed In Routes
export const SIGN_UP = '/signup';
export const USER = '/users'; //New User Home
export const ACCOUNT = '/account';
export const NOTIFICATIONS = '/notifications';
export const RESOURCES = '/resources';
export const PORTFOLIOS = '/portfolios';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
//export const HOME = '/home'; //Orginal User Home